<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
    width="650px"
  >
    <template v-slot:authCode>
      <div style="position: relative">
          <el-input v-model="formData.authCode"  :placeholder="$t('adminAccount.codeText')" maxlength="6" >
          <el-button slot="suffix" size="mini"  @click.stop="sendcode" v-if="codeShow">{{$t('adminAccount.getCode')}}<span id="count"></span>
          </el-button>
          <el-button slot="suffix" size="mini" disabled v-if="!codeShow">{{$t('adminAccount.sendCode')}}{{count}}s</el-button>
        </el-input>
        <div class="el-form-item__error" v-show="codeErrshow">{{codeErrText}}</div>
      </div>
    </template>
    <!-- 验证码 -->
  </dialog-form-list>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const cmd = this.$store.state.cmd
    const self = this
    return {
      cmd: cmd,
      formData: {
        scope: 'COMPANY',
        authCodeSeqNo:''
      },
      count: '', // 验证码倒计时
      timer:null ,
      codeShow: true,
      codeErrText:'',
      codeErrshow: false,
      config: [
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
          rules: {
            // noNull: true,
            type: 'mobile',
          },
          tagProps: {
            maxLength: 11,
          },
          tagEvents:{
            change(data){
              // if (self.cmd !== 'add') return
              const reg = /^[1][3-9][0-9]{9}$/
              if (reg.test(data)) {
                if (self.cmd !== 'add'){
                  if(data === self.tableRow.mobile) {
                    self.config[2].hidden = true
                  }else {
                    self.config[2].hidden = false
                  }
                }else{
                  self.config[2].hidden = false
                }
              }else{
                self.config[2].hidden = true
              }
            },
          }
        },
        // 获取手机验证吗
        {
          tag: 'slot-content',
          label: this.$t('adminAccount.code'),
          prop: 'authCode',
          rules: {
            noNull: true,
          },
          hidden: true,
          tagProps: {
            placeholder: this.$t('base.validateCode'),
          },
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
          rules: {
            noNull: true,
          },
          tagProps: {
            disabled: cmd === 'add' ? false : true,
            maxLength: 32,
          },
        },
        // lang:密码
        {
          label: this.$t('adminAccount.password'),
          prop: 'password',
          hidden: cmd === 'add' ? false : true,
          rules: {
            noNull: true,
            type: 'password',
          },
          tagProps: {
            showPassword: true,
            placeholder: this.$t('base.validatePassword'),
          },
        },
        // lang:是否在线支持人员
        {
          tag: 'el-select',
          label: this.$t('adminAccount.serviceProvide'),
          prop: 'serviceProvide',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.yesOrNo(),
          },
        },
        // lang: 所属部门
        {
          tag: 'el-cascader',
          label: this.$t('adminAccount.accountGroup'),
          prop: 'accountGroup',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
        // lang:是否可添加外部联系人
        {
          tag: 'el-select',
          label: this.$t('adminAccount.canAddOutsideUserAccount'),
          prop: 'canAddOutsideUserAccount',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.yesOrNo(),
          },
          tagEvents: {
            change(data) {
              if (data === 'NO' && cmd !== 'add') {
                self.config[8].hidden = false
              } else {
                self.config[8].hidden = true
              }
            },
          },
        },
        // lang:是否清空此账号的外部联系人
        {
          tag: 'el-select',
          label: this.$t('adminAccount.shouldRemoveOutsideUserAccount'),
          prop: 'shouldRemoveOutsideUserAccount',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.yesOrNo(),
          },
        },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickName',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
    this.userAccountGroupFindTree()
  },
  methods: {
    // 查询部门
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          companyId: this.$store.getters.company.id,
          scope: 'COMPANY',
        },
      }).then((data) => {
        this.config[6].tagProps.options = data
      })
    },
    // 设置默认值
    setDefaultValue() {
      const tableRow = this.$util.copyData(this.tableRow)
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          console.log(this.formData, this.tableRow)
          // 禁掉账号类型
          this.config[5].tagProps.disabled = true
          // 处理所属部门
          this.formData.accountGroup = this.$util.getTreeIds(
            this.tableRow.accountGroup
          )
          // 处理是否清空此账号的外部联系人
          if (this.formData.canAddOutsideUserAccount === 'NO') {
            this.config[8].hidden = false
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      // if (formData.smscode !== this.smscode) return
      // 处理密码
      if (this.cmd !== 'add') delete formData.password
      // 处理所属部门
      formData.accountGroup  = {
        id: formData.accountGroup.pop(),
      }
      // 处理是否清空此账号的外部联系人
      if (formData.canAddOutsideUserAccount === 'YES' || this.cmd === 'add') {
        delete formData.shouldRemoveOutsideUserAccount
      }
      if (!formData.serviceProvide) {
        formData.serviceProvide = 'NO'
      }
      if (!formData.subscribeType) {
        formData.subscribeType = 'AUTO'
      }
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      const { accountGroup } = formData
      formData.departmentId = accountGroup.id
      delete formData.accountGroup
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      }).catch((error) =>{
        this.$element.showMsg(this.$t('serverCode.' + error.msg), 'warning')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      console.log(tableRow)
      Object.assign(tableRow, formData)
      formData.id = tableRow.id
      formData.departmentId = tableRow.accountGroup.id
      delete formData.accountGroup
      console.log(formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountUpdate,
        data: formData,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
    // 验证验证码
    // onChangeCode(val) {
    //   console.log(this.formData.authCode)
    //   if (val !== this.formData.authCode) {
    //     this.codeErrshow = true
    //     this.codeErrText = this.$t('base.codeErrText')
    //     return
    //   }else {
    //     this.codeErrshow = false
    //     this.codeErrText = ""
    //   }
    // },
    onInputCode() {
      this.codeErrshow = false
      this.codeErrText = ""
    },
    // 获取验证码
    sendcode() {
      // 验证码
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sendCode,
        data:{
          mobile: this.formData.mobile,
        }
      }).then((data) => {
        console.log(data)
        this.formData.authCodeSeqNo = data
      })
      const TIME_COUNT = 30   //倒计时60秒
      if(!this.timer) {
        this.count = TIME_COUNT
        this.codeShow = false
        this.timer = setInterval(() => {
          if(this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.codeShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>
